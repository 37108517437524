import logger from "@utils/logger";
import {
  filterBundleList,
  getActiveFilters,
  getQSActiveFilters,
} from "@utils/listing";
import { isTestingEnv } from "@utils/helpers";

const reducer = (state, action) => {
  let nextState = {};

  switch (action.type) {
    case "SET_BUNDLES":
      nextState = {
        ...state,
        bundles: action.bundles,
        loading: false,
      };
      break;

    case "SET_QUERY_FILTERS":
      if (Object.keys(action.queryFilters).length > 0) {
        const qsFilterKey = Object.keys(action.queryFilters)
        const newFilter = {
          property: qsFilterKey,
          value: action.queryFilters[qsFilterKey],
        }
        const active = getQSActiveFilters(newFilter, state.filters.active);
        const result = state.bundles.filter((bundle) =>
          filterBundleList(bundle, active)
        );

        nextState = {
          ...state,
          filters: {
            ...state.filters,
            query: action.queryFilters,
            active,
            result,
          },
        };
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      } else nextState = state;
      break;

    case "TOGGLE_FILTER":
      let active = getActiveFilters(action.filter, state.filters.active)

      if (action.removeOthers) {
        active = getActiveFilters(action.filter, {})
      }

      const result = state.bundles.filter((bundle) =>
        filterBundleList(bundle, active)
      );
      // const activable = Object.keys(active).length > 0 ? getActivableFilters(result) : [];

      nextState = {
        ...state,
        filterLoading: true,
        filters: { ...state.filters, result, active },
      };
      break;

    case "SET_LOADING":
      nextState = {
        ...state,
        loading: action.loading,
      };
      break;

    case "SET_FILTER_LOADING":
      nextState = {
        ...state,
        filterLoading: action.loading,
      };
      break;

    case "SET_BUNDLES_FILTERS":
      nextState = {
        ...state,
        filters: {
          ...state.filters,
          all: action.filters,
        },
      };
      break;

    default:
      nextState = state;
  }

  if (isTestingEnv()) {
    logger(state, nextState, action);
  }

  return nextState;
};

export default reducer;
