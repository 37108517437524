import { getDiscountPrice } from "./helpers";

const sendEvent = (event) => {
  if (isDataLayerAvailable()) {
    window.dataLayer.push(event);
  } else {
    console.log(event);
  }
};

const getCurrency = () => {
  if (isDataLayerAvailable()) {
    return window.dataLayer[0].currency;
  } else {
    return null;
  }
}

const getCurrencySymbol = () => {
  if (isDataLayerAvailable()) {
    return window.dataLayer[0].currencySymbol;
  } else {
    return null;
  }
}

const isDataLayerAvailable = () => {
  return window.dataLayer && window.dataLayer.push;
};

export const onApplyFilter = (filterName) => {
  const event = {
    'event': 'loadEventData',
    'eventCategory': 'PDP',
    'eventAction': 'Product Bundle_Apply Filter',
    'eventLabel': filterName,
    'nonInteractive': false,
    'customMetrics': {},
    'customVariables': {
      'functionality': 'Product Bundle',
    },
    _clear: true
  }
  return sendEvent(event)
}

export const onGoToBundle = (bundleName, originalPrice, discountPercentage) => {
  const event = {
    'event': 'loadEventData',
    'eventCategory': 'PDP',
    'eventAction': 'Product Bundle_Shop Bundle',
    'eventLabel': bundleName,
    'nonInteractive': false,
    'customMetrics': {
      'bundleValue': getDiscountPrice(originalPrice, discountPercentage),
      'bundleOriginalValue': originalPrice
    },
    'customVariables': {
      'functionality': 'Product Bundle',
      'bundleName': bundleName,
      'bundleDiscount': discountPercentage + '%'
    },
    _clear: true
  }
  return sendEvent(event)
}

export const onGoToNextProduct = (bundleName, originalPrice, discountPercentage, step) => {
  const event = {
    'event': 'loadEventData',
    'eventCategory': 'PDP',
    'eventAction': 'Product Bundle_Proceed to Next Product',
    'eventLabel': bundleName,
    'nonInteractive': false,
    'customMetrics': {
      'bundleValue': getDiscountPrice(originalPrice, discountPercentage),
      'bundleOriginalValue': originalPrice
    },
    'customVariables': {
      'functionality': 'Product Bundle',
      'bundleName': bundleName,
      'bundleDiscount': discountPercentage + '%',
      'bundleStep': step
    },
    _clear: true
  }
  return sendEvent(event)
}

export const addToCart = (products, bundle, stepIndex) => {
  const event = {
    event: "addToCart",
    ecommerce: {
      currencyCode: getCurrency(),
      currencySymbol: getCurrencySymbol(),
      add: {
        products: products.map((product) => ({
          bundleId: bundle.name,
          colorCode: product.colorCode,
          colorDescription: product.colorDescription,
          id: product.sku,
          itemID: product.id,
          name: product.name,
          price: product.price,
          productID: product.sku,
          quantity: "1",
          size: product.size,
          styleCode: product.sku,
          variant: "COLOR: " + product.colorDescription + " / SIZE: " + product.size,
          vn: product.id
        }))
      },
    },
    customMetrics: {
      bundleValue: getDiscountPrice(bundle.price, bundle.discount),
      bundleOriginalValue: bundle.price,
    },
    customVariables: {
      functionality: 'Product Bundle',
      bundleName: bundle.name,
      bundleDiscount: bundle.discount + '%',
      bundleStep: stepIndex ? stepIndex + 1 : 1
    },
    _clear: true,
  };
  return sendEvent(event);
};


export const colorVariantChange = (sku, colorDesc) => {
  const event = {
    'event': 'loadEventData',
    'eventCategory': 'PDP',
    'eventAction': 'Color Click',
    'eventLabel': `${sku} - ${colorDesc}`,  // eg."NF0A4R2A: Men’s Apex Bionic Jacket",
    'nonInteractive': false,
    'customMetrics': {},
    _clear: true,
    customVariables: {
      functionality: 'Product Bundle',
    },
  }
  return sendEvent(event)
}


export const productDetailView = (product, bundle, stepIndex) => {
  const event = {
    event: 'productDetailView',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Product Detail View',
    eventLabel: `${product.partNumber} - ${product.colorDescription}`,
    nonInteractive: true,
    ecommerce: {
      currencyCode: getCurrency(),
      currencySymbol: getCurrencySymbol(),
      detail: {
        actionField: {
          viewType: "PDP",
        },
        products: [{
          avgRating: product.avgRating,
          bundleId: bundle.name,
          colorCode: product.colorCode,
          colorDescription: product.colorDescription,
          id: product.sku,
          name: product.name,
          numReviews: product.numReviews,
          price: product.price,
          saleDiscountAmount: product.saleDiscountAmount,
          styleCode: product.sku,
          variant: product.variant
        }]
      }
    },
    customMetrics: {
      bundleValue: getDiscountPrice(bundle.price, bundle.discount),
      bundleOriginalValue: bundle.price,
    },
    customVariables: {
      functionality: 'Product Bundle',
      bundleName: bundle.name,
      bundleDiscount: bundle.discount + '%',
      bundleStep: stepIndex + 1
    },
    _clear: true
  }
  return sendEvent(event)
}


export const productImpressions = (bundles) => {
  const event = {
    'event': 'productImpressions',
    'eventCategory': 'Enhanced Ecommerce',
    'eventAction': 'Product Impressions',
    'nonInteractive': true,
    ecommerce: {
      currencyCode: getCurrency(),
      currencySymbol: getCurrencySymbol(),
      impressions: {
        products: bundles.reduce((acc, bundle, bundleIndex) => {
          const data = JSON.parse(bundle.data);
          const products = JSON.parse(bundle.products).map((product, productIndex) => ({
            bundleId: bundle.name,
            colorCode: product.variant || data?.fallback_variants[product.sku],
            id: product.sku,
            // onSale: true,
            position: (2 * bundleIndex) + (productIndex + 1),
            // saleDiscountAmount: bundle.discount,
          }))
          return [...acc, ...products]
        }, [])
      },
    },
    customVariables: {
      functionality: 'Product Bundle',
    },
    _clear: true
  }
  return sendEvent(event)
}

