/* styles */
import style from '@styles/pdp/Price.module.scss';

/* utils */
import { formatPriceWithCurrency } from "@utils/helpers";

export default function Price ({listPrice, offerPrice}){
    return <div className={style.priceContainer}>
        {listPrice !== offerPrice ? <>
            <span className={style.priceCrossed}>{listPrice}</span>
            <span className={style.priceSale}>{formatPriceWithCurrency(offerPrice)}</span>
        </> : <span className={style.price}>{listPrice}</span>}
    </div>

}