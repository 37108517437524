import { useEffect, useReducer, useState } from 'react'
import { getPublicBundleList } from '@utils/fetch';

/* components */
import Header from '@components/plp/Header';
import Filters from '@components/plp/Filters';
import BundleList from '@components/plp/BundleList';
import ListingNotFound from '@components/plp/ListingNotFound';
import TNFLoader from "@components/generic/TNFLoader";
import CircleLoader from '@components/plp/CircleLoader';

/* context */
import context, { initialContext } from '@context/plp';
import reducer from '@context/plp/reducer';
import { setBundles } from '@context/plp/actions';
import { productImpressions } from '@utils/analytics';

function Listing() {
    const [state, dispatch] = useReducer(reducer, initialContext);
    const [contentVisible, setContentVisible] = useState(false);
    const [preview, setPreview] = useState(false)

    useEffect(() => {
        setPreview(window.location.href.indexOf("?preview=") > 0 ? true : false)
        getPublicBundleList({ published: window.location.href.indexOf("?preview=") > 0 ? 0 : 1 }).then((data) => {
            if (data.status === 200) {
                setTimeout(() => setBundles({ dispatch }, data.bundles), 500);
                setTimeout(() => setContentVisible(true), 600);
                productImpressions(data.bundles)
            }
        });
        const banner = document.querySelector(
          "#body-container > div > div:nth-child(1) > div > div > picture"
        );
        if (banner) {
          banner.style.display = "block";
        }
    }, []);

    return (
      <context.Provider value={{ state, dispatch }}>
        <div className="b-app">
        {state.loading ? <TNFLoader /> : (
          <div className={`b-container ${contentVisible ? "" : "b-container--hidden"}`}>
            <Header />
            {!state.bundles?.length ? (
              <ListingNotFound />
            ) : (
              <>
                <Filters bundles={state.bundles} />
                {state.filterLoading ? <CircleLoader /> : (<BundleList preview={preview} />)}
              </>
            )}
          </div>)}
        </div>
      </context.Provider>
    );
}

export default Listing;