import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import '../../i18n';

/* styles */
import style from '@styles/plp/Header.module.scss';

export default function Header() {
  const { t } = useTranslation('translation');
  const [hideIntro, setHideIntro] = useState(false);

  // useEffect(() => {
  //   const country = document.querySelector('#root').getAttribute('data-view-id');
  //   if(country.toLowerCase() === 'en_lu') {
  //     setHideIntro(true)
  //   }
  // }, [])

  return (
    !hideIntro && (
      <div className={style.container}>
        <h1 className={style.title}>{t("plp.FIND-YOUR-BUNDLE")}</h1>
        <p className={style.text}>{t("plp.BUNDLES-DESCRIPTION")}</p>
      </div>
    )
  );
}