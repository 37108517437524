import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next";
import "../../i18n";

/* styles */
import style from '@styles/plp/Filters.module.scss';

/* context */
import context from "@context/plp";
import { setBundlesFilters, toggleFilter, setLoading, setFilterLoading } from "@context/plp/actions";

/* utils */
import { getFiltersFromBundles } from "@utils/listing";
import { onApplyFilter } from "@utils/analytics";

export default function Filters({ bundles }) {
  const { state, dispatch } = useContext(context);
  const { t } = useTranslation("translation");

  useEffect(() => {
    const filters = getFiltersFromBundles(bundles)
    setBundlesFilters({ dispatch }, filters, t)
  }, [bundles, dispatch])


  return (
    <div className={style.container}>
      {Object.keys(state.filters.all).map((key) => {
        if (typeof state.filters.all[key] === 'object') return state.filters.all[key].map((filter, index) => <FilterToggler key={index} label={filter} property={key} value={filter} />)
        if (typeof state.filters.all[key] === 'boolean' && state.filters.all[key]) return <FilterToggler key={key} label={key} property={key} value={!state.filters.active.accessories} />
        return null
      })}
    </div>
  );
}

function FilterToggler({ value, property, label }) {
  const { state, dispatch } = useContext(context);
  const { t } = useTranslation("translation");

  const getClassName = () => {
    if((state.filters.active[property] && value == state.filters.active[property][0]) || (typeof value == 'boolean' && state.filters.active[property]))
      return `${style.button} ${style.buttonActive}`
    if(Object.keys(state.filters.active).length){
      return `${style.button} ${style.buttonDisabled}`
    }
    return style.button
  }

  const handleClick = () => {
    onApplyFilter(value)
    toggleFilter({ state, dispatch }, { property, value })

    setTimeout(() => {
      setFilterLoading({ dispatch }, false)
    }, 250)
  }


  return <button className={getClassName()} onClick={handleClick}>
    <span>{t(`${label.toUpperCase()}`)}</span>
    <svg viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 5.5L78 77.5M77.5 5L5.5 78" stroke="white" strokeWidth="10" />
    </svg>
  </button>
}