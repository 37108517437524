export const filterBundleList = (bundle, filters) => {

    if (Object.keys(filters).length) {

        return Object.keys(filters).reduce((res, key) => {

            if (key === 'activity') {
                return filters[key].some(activity => bundle.activity.includes(activity.toUpperCase())) ? res : false
            }
            if (key === 'gender') {
                return filters[key].includes(bundle[key]) ? res : false
            }
            if (key === 'accessories') {
                return bundle.accessories_bundle ? res : false
            }

            return false
        }, true)
    }

    else return true
}

export const getFiltersFromBundles = (bundles) => {
    const bundleFilters = {
        gender: [],
        activity: [],
        accessories: false
    }

    for (const bundle of bundles) {
        // genders
        if (!bundleFilters.gender.includes(bundle.gender)) bundleFilters.gender.push(bundle.gender)

        /*
        // activity
        bundle.activity.split(';').forEach(activity => {
            if (!bundleFilters.activity.includes(activity.toLowerCase())) bundleFilters.activity.push(activity.toLowerCase())
        })
        */

        // accessories
        if (!bundleFilters.accessories && bundle.accessories_bundle) bundleFilters.accessories = true
    }

    return bundleFilters
}

export const getActivableFilters = (bundles) => {
    const bundleFilters = getFiltersFromBundles(bundles)
    const activableFilters = []

    for (const filter in bundleFilters) {
        if (typeof bundleFilters[filter] === 'object') bundleFilters[filter].map(value => activableFilters.push(value))
        if (typeof bundleFilters[filter] === 'boolean' && bundleFilters[filter]) activableFilters.push('accessories')
    }
    return activableFilters
}

export const getActiveFilters = (filter, active) => {
    const res = { ...active }

    if (typeof filter.value !== 'boolean') {
        const activeByProperty = active[filter.property] || {}
        const newActiveByProperty = !activeByProperty.length ? [filter.value] : activeByProperty.includes(filter.value) ? activeByProperty.filter(value => value !== filter.value) : [...activeByProperty, filter.value]
        if (newActiveByProperty.length) res[filter.property] = newActiveByProperty
        else delete res[filter.property]
    } else {
        if(active[filter.property])
            delete res[filter.property]
        else res[filter.property] = true
    }
    return res
}
export const getQSActiveFilters = (filter, active) => {
    // fork of getActiveFilters but without toggle behaviour
    const res = { ...active }
    if (typeof filter.value !== 'boolean') {
        const activeByProperty = active[filter.property] || {}
        const newActiveByProperty = !activeByProperty.length ? [filter.value] : [...activeByProperty, filter.value]
        if (newActiveByProperty.length) res[filter.property] = newActiveByProperty
    } else {
        res[filter.property] = true
    }
    return res
}

export const validateQueryFilters = (queryFilters, allFilters) => {
    return Object.keys(queryFilters).every((key) => {
      if (key === "accessories") return true;
      else return allFilters[key]?.includes(queryFilters[key]);
    });
}