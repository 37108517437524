import { getQueryParams } from "@utils/helpers";
import { validateQueryFilters } from "@utils/listing";
  
const actions = {
    SET_BUNDLES: 'SET_BUNDLES',
    SET_QUERY_FILTERS: 'SET_QUERY_FILTERS',
    TOGGLE_FILTER: 'TOGGLE_FILTER',
    SET_LOADING: 'SET_LOADING',
    SET_BUNDLES_FILTERS: 'SET_BUNDLES_FILTERS',
    SET_FILTER_LOADING: 'SET_FILTER_LOADING',
}

export default actions



export function setBundles({ dispatch }, bundles) {
    dispatch({
        type: actions.SET_BUNDLES,
        bundles: bundles.map(bundle => ({
            ...bundle,
            products: JSON.parse(bundle.products),
            data: JSON.parse(bundle.data)
        }))
    })
}

export function setBundlesFilters({ dispatch }, filters, translations) {
    dispatch({ type: actions.SET_BUNDLES_FILTERS, filters })

    // for now, only 1 filter can be activated (gender OR accessories)
    const queryFilters = {}
    const params = getQueryParams()
    const activableKey = Object.keys(params).filter(key=>key!=='preview')[0]

    const validFilter = activableKey ? validateQueryFilters({[activableKey]: translations(params[activableKey]).toLowerCase()}, filters) : false;

    if(validFilter){
        if(activableKey !== 'accessories'){
            const value = params[activableKey]
            if (value && translations(value)){  
                queryFilters[activableKey] = translations(value).toLowerCase()
            }
        } else {
            // accessories filter is boolean
            queryFilters[activableKey] = true
        }
    
        dispatch({
            type: actions.SET_QUERY_FILTERS,
            queryFilters
        })
    }

}

export function toggleFilter({ state, dispatch }, filter) {
    if(typeof filter.value !== 'boolean'){
        if(Object.keys(state.filters.active).length == 0 || state.filters.active[filter.property]?.includes(filter.value)){
            dispatch({
                type: actions.TOGGLE_FILTER,
                filter,
            })    
        } else {
            dispatch({
                type: actions.TOGGLE_FILTER,
                filter,
                removeOthers: true
            })  
        }
    } else {
        // accessory
        if(Object.keys(state.filters.active).length == 0 || state.filters.active[filter.property]){
            dispatch({
                type: actions.TOGGLE_FILTER,
                filter
            })
        } else {
            dispatch({
                type: actions.TOGGLE_FILTER,
                filter,
                removeOthers: true
            })  
        }
    }
}

export function setLoading({ dispatch }, loading) {
    dispatch({
        type: actions.SET_LOADING,
        loading
    })
}

export function setFilterLoading({ dispatch }, loading) {
    dispatch({
        type: actions.SET_FILTER_LOADING,
        loading
    })
}