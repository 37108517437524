import { useContext } from "react";

/* components */
import BundleItem from "@components/plp/BundleItem";
import CircleLoader from "@components/plp/CircleLoader";

/* styles */
import style from "@styles/plp/BundleList.module.scss";

/* context */
import context from "@context/plp";

export default function BundleList({preview}) {
  const { state } = useContext(context);

  const renderList = state.filters.result.length
    ? state.filters.result
    : state.bundles;

  const hasThreeItemsBundles = renderList.some(b=>b.products.length > 2)
  
  return (
    <div className={style.container}>
      {state.loading ? (
        <div className={style.circleContainer}>
          <CircleLoader />
        </div>
      ) : (
        renderList.map((bundle, index) => (
          <BundleItem key={index} bundle={bundle} preview={preview} threeItemsLayout={hasThreeItemsBundles} />
        ))
      )}
    </div>
  );
}
