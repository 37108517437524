export const getColorPickerThumbs = (sku, variant) => {
  return `https://images.thenorthface.com/is/image/TheNorthFaceEU/${sku}_${variant}_hero?$60x70$`;
};

export const sortSizes = (sizesList) => {
  const order = ["XXS", "XS","XS/S", "S", "S/M", "M","M/L", "L", "L/XL", "XL", "XXL", "3XL", "1X", "2X", "3X"];
  // , "2","3","4","5","6","7"
  const sortWithOrder = (a, b) => {
    return order.indexOf(a.size) - order.indexOf(b.size);
  };
  const sortWithNumber = (a, b) => +a.size - +b.size;

  const sortWithAge = (a, b) => +a.size?.split(' ')[0] - +b.size?.split(' ')[0]; //todo not [0] but parse int split(' ')[0]

  console.log(sizesList[0]?.size?.[0])
  return sizesList.sort(
    isNaN(sizesList[0]?.size) ? isNaN(sizesList[0]?.size?.split(' ')[0]) ? sortWithOrder : sortWithAge : sortWithNumber
  );
};

export const getUniqueID = (sizesObj) => {
  if (typeof sizesObj !== "undefined") {
    return sizesObj.uniqueID;
  }
};

export const serializeProductData = (
  data,
  availability,
  variant,
  colors,
  prices
) => {
  //Prepare and match data for size and inseam availability
  const sizeUniqueID = getUniqueID(
    data.CatalogEntryView[0].Attributes?.find(
      (attr) =>
        attr.identifier === "DIM1_" + data.CatalogEntryView[0].partNumber
    )
  );
  const inseamUniqueID = getUniqueID(
    data.CatalogEntryView[0].Attributes?.find(
      (attr) =>
        attr.identifier === "DIM2_" + data.CatalogEntryView[0].partNumber
    )
  );

  const colorUniqueID = getUniqueID(
    data.CatalogEntryView[0].Attributes?.find(
      (attr) =>
        attr.identifier === "COLOR_" + data.CatalogEntryView[0].partNumber
    )
  );

  const finalPrice = prices.pricing[colorUniqueID][variant.code].highPrice;

  const sizesByColor = sortSizes(
    Object.keys(availability.partNumbers).reduce((acc, catId) => {
      const id = availability.partNumbers[catId];
      const loopVariant = id.split(":")[2];
      const inseam = id.split(":")[4];
      if (loopVariant === variant.code) {
        acc.push({
          catId: catId,
          label: availability.partNumbers[catId]
            .split(":")[3]
            .replace("SM", "S/M")
            .replace("LXL", "L/XL"),
          inseam: inseam,
        });
      }
      return acc;
    }, [])
  );

  // sizesData == tutte le size possibili per quel prodotto
  // uniqueID (inseamUniqueID, sizeUniqueID): "7000000000000094721" per poi recuperare i valori in attributes nella risposta della getAvailability
  // sizesByColor == tutte le size disponobili per quella variante colore con catID da incrociare con quello che c'è in sizes.attributes[sizeUniqueID]
  // sizeCatIdCombination === tutte le size del prodotto con indicazione di quale catId è disponibile per quella taglia

  const sizeCatIdCombination = availability.attributes[sizeUniqueID];
  const inseamCatIdCombination = availability.attributes[inseamUniqueID];
  //   const colorCatIdCombination = availability.attributes[colorUniqueID];

  //Check inseam stock and combine with color variant stock
  const inseamAvailableForVariant = inseamCatIdCombination?.reduce(
    (acc, item) => {
      let inseamInStock = item.catentryId.some(
        (catentryId) => availability.stock[catentryId] > 0
      );

      const obj = sizesByColor.find((obj) =>
        item.catentryId.includes(+obj.catId)
      );

      acc.push({
        inseam: item.display,
        stock: obj?.catId ? inseamInStock : false,
        inseamKey: obj?.inseam,
      });
      return acc;
    },
    []
  );

  // Check if new variant has at least one inseam with valid stock otherwise put out of stock on size
  const variantHasStock = inseamUniqueID
    ? inseamAvailableForVariant.some((item) => item.stock === true)
    : true;

  let sizesAvailableForVariant = sizeCatIdCombination.reduce((acc, item) => {
    const obj = sizesByColor.filter((obj) =>
      item.catentryId.includes(+obj.catId)
    );

    obj.map(sizeByInseam => {
      const sizeHasStock = availability.stock[sizeByInseam.catId] > 0;

      acc.push({
        size: item.display,
        sizeKey: sizeByInseam.label,
        stock: variantHasStock && sizeHasStock,
        stockLeft: availability.stock[sizeByInseam.catId],
        catId: sizeByInseam?.catId,
        inseamKey: sizeByInseam?.inseam,
      });
      return acc;
    })

    return acc;
  }, []);

  const variantIsOOS = !sizesAvailableForVariant.some(
    (item) => item.stock === true
  );

  const sizeChartContext = data.CatalogEntryView[0].Attributes?.find(
    (attr) => attr.identifier === "SIZE_CONTEXT"
  )?.Values[0].values;

  // Check for specific case when there's inseam but no inseamUniqueID
  if (!inseamUniqueID) {
    const count = sizesAvailableForVariant.reduce(function (n, val) {
      return n + (val.sizeKey === "OS");
    }, 0);
    if (count > 1) {
      if(sizesAvailableForVariant[0].stock){
      sizesAvailableForVariant.pop()
      } else {
        sizesAvailableForVariant.shift();
      }
    } 
  }

  return {
    ...data.CatalogEntryView[0],
    sizeContext: sizeChartContext !== "DISABLED" ? sizeChartContext : false,
    sizes: sizesAvailableForVariant,
    inseam: inseamAvailableForVariant,
    benefits: data.CatalogEntryView[0].Attributes?.find(
      (attr) => attr.identifier === "FILTER_BENEFIT"
    )
      ?.Values.map((val) => ({
        label: val.values,
        icon: val.extendedValue ? val.extendedValue[0].value : null,
        description: val.extendedValue ? val.extendedValue[1].value : null,
      }))
      .slice(0, 4),
    technology: data.CatalogEntryView[0].Attributes?.find(
      (attr) => attr.identifier === "TECHNOLOGY_ITEMS"
    )?.Values.map((val) => ({
      label: val.values,
      description: val?.extendedValue[0]?.value,
    })),
    intendedUse: data.CatalogEntryView[0].Attributes?.find(
      (attr) => attr.identifier === "INTENDED_USE"
    )?.Values.map((val) => ({
      label: val.values,
      icon: val.extendedValue ? val.extendedValue[0].value : null,
    })),
    howToCare: data.CatalogEntryView[0].Attributes?.find(
      (attr) => attr.identifier === "FILTER_HOW_TO_CARE"
    )?.Values.map((val) => ({
      label: val.values,
      icon: val.extendedValue ? val.extendedValue[0].value : null,
      description: val.extendedValue ? val.extendedValue[1].value : null,
    })),
    features: data.CatalogEntryView[0].Attributes?.find(
      (attr) => attr.identifier === "FEATURE"
    )?.Values.map((val) => val.values),
    hasInseam: inseamUniqueID ? true : false,
    variant,
    colors,
    isOOS: variantIsOOS,
    finalPrice,
    partNumbers: availability.partNumbers,
  };
};

export const getStoreKey = (bundleId) =>
  `${
    document.getElementById("root").dataset.viewId
  }-bundleSettings-${bundleId}`;

export const getConfiguredProducts = (bundleConfiguration) =>
  Object.keys(bundleConfiguration).filter(
    (sku) => bundleConfiguration[sku].size
  );

export const formatSize = (size) => size;

export const getSizeChart = async (sizeContext) => {
  const domain =
    window.location.hostname === "localhost" ||
    window.location.hostname === "dev-eu-tnf-product-finder.netlify.app"
      ? "www.thenorthface.co.uk"
      : window.location.hostname;
  const locale = document
    .querySelector("#root")
    .getAttribute("data-view-id")
    .toLowerCase();
  const response = await fetch(
    `https://${domain}/${locale}/customer-service/size-chart/${sizeContext}.contentonly.html?requesttype=ajax`
  );
  return response.text();
};

export const setConfiguredAndNextSteps = (nextState) => {
  nextState.step = {
    ...nextState.step,
    configured: getConfiguredProducts(nextState.bundleConfiguration).length,
  };

  nextState.step.nextSteps = nextState.bundle.products.reduce(
    (steps, product, index) => {
      if (
        !getConfiguredProducts(nextState.bundleConfiguration).includes(
          product.sku
        ) &&
        nextState.step.index !== index
      ) {
        steps.push(index);
      }
      return steps;
    },
    []
  );

  return nextState;
};

export const removeSizeOnInseamChange = (nextState, action) => {
  const newInseamSizeStock = nextState.step.product.sizes.find(
    ({ size, inseam }) =>
      size === nextState.bundleConfiguration[action.product].size &&
      inseam === action.inseam
  )?.stock;

  if (!newInseamSizeStock) {
    delete nextState.bundleConfiguration[action.product].size;
    delete nextState.bundleConfiguration[action.product].catId;
  }

  return nextState;
};

export const getDefaultInseam = (inseams) => {
  if (inseams) {
    // return REG if exists
    if (inseams?.some((inseam) => inseam.inseamKey === "REG" && inseam.stock))
      return "REG";
    // return first inseam
    return inseams[0].stock ? inseams[0].inseam : null;
  } else {
    return null;
  }
};

export const getGalleryModal = async (product, variant) => {
  const urlLangId =
    document.querySelector('meta[name="langId"]')?.content || "-11";
  const storeId =
    document.querySelector('meta[name="storeId"]')?.content || "7005";
  const domain =
    window.location.hostname === "localhost" ||
    window.location.hostname === "dev-eu-tnf-product-finder.netlify.app"
      ? "www.thenorthface.co.uk"
      : window.location.hostname;
  const response = await fetch(
    `https://${domain}/shop/VFQuickViewDisplay?variationId=${variant}&urlLangId=${urlLangId}&langId=${urlLangId}&productId=${product}&storeId=${storeId}&requesttype=ajax`
  );
  return response.text();
};

export const checkAvailableInseam = (action) => {
  if (
    !(
      action?.inseamData?.inseamKey ||
      getDefaultInseam(action.stepProduct.inseam)
    )
  ) {
    return false;
  }
  return true;
};


export const getDefaultOneSizeIfAvailable = (sizes, inseamKey) => {
  let size = null
  if(inseamKey){
  size = sizes.find(
    (item) => item.stock && item.inseamKey === inseamKey
  );
  } else{
    if(sizes[0].stock) size = sizes[0]
  }
  return size
}