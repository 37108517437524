import "./styles/index.scss";
import { HashRouter, Routes, Route } from "react-router-dom";
import { useEffect, useState } from 'react'

/* pages */
import Listing from "@pages/Listing";
import Detail from "@pages/Detail";


function App() {

  const [preview, setPreview] = useState(false)

  useEffect(() => {
    setPreview(window.location.href.indexOf("?preview=") > 0 ? true :  false);
  }, []);

  return (
      <HashRouter>
        <Routes>
          <Route path="/" element={<Listing preview={preview}/>} />
          <Route path="/bundle-detail/:id" element={<Detail preview={preview}/>} />
        </Routes>
      </HashRouter>
  );
}

export default App;
