import { createContext } from "react";

const context = createContext();

export const initialContext = {
    bundles: [],
    filters: {
        all: {},
        active: {},
        // activable: [],
        result: []
    },
    loading: true,
    sizesPopup: false
}

export default context