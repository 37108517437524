import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { ReactComponent as Plus } from '@svg/plus_desk.svg';

/* styles */
import style from "@styles/plp/BundleItem.module.scss";

/* utils */
import { onGoToBundle } from "@utils/analytics";
import { getImageUrl, getDiscountPrice, getListProductVariant, getCurrency } from "@utils/helpers";


export default function BundleItem({ bundle, preview, threeItemsLayout }) {
  const { products, data } = bundle;
  const { t } = useTranslation("translation");
  const detailUrl = "/bundle-detail/";

  const getThumbsClassName = () => {
    let className = style.thumbs
    if(!threeItemsLayout) className += ' ' + style['thumbs--two-items']
    className += ' ' + style.desktop
    return className
  }

  return (
    <div className={style.container}>
      <div className={getThumbsClassName()}>
        {products.map((product, index) =>
          <Thumb key={index} data={{ index, product, data, isLast: products.length - 1 !== index }} />
        )}
      </div>
      <div className={style.info}>
        <span className={style.discount}>
          {t("plp.SAVE").replace('X', bundle.discount)}
        </span>
        <h2 className={style.name}>{bundle.name}</h2>
        <div className={`${style.thumbs} ${style.mobile}`}>
          {products.map((product, index) =>
            <Thumb key={index} data={{
              index,
              product,
              data,
              isLast: products.length - 1 !== index
            }} />
          )}
        </div>
        <span className={style.priceCrossed}>
          {getCurrency()} {bundle.price.toFixed(2)}
        </span>
        <span className={style.price}>
          {getCurrency()} {getDiscountPrice(bundle.price, bundle.discount).toFixed(2)}
        </span>
        <Link onClick={() => onGoToBundle(bundle.name,bundle.price,bundle.discount)} to={detailUrl + bundle.id + (preview ? '?preview=true' : '')} className={style.cta}>
          {t("plp.SHOP-BUNDLE")}
        </Link>
      </div>
    </div>
  );
}

function Thumb(props) {
  const { product, data, isLast } = props.data;
  const firstImg = {
    sku: product.sku,
    variant: getListProductVariant(product.variant, data.fallback_variants[product.sku], data.fallback_variants_availability?.[product.sku]),
    type: data.plp_images?.first?.[product.sku] ? data.plp_images?.first?.[product.sku] : 'hero'
  }
  const secondImg = {
    sku: product.sku,
    variant: getListProductVariant(product.variant, data.fallback_variants[product.sku], data.fallback_variants_availability?.[product.sku]),
    type: data.plp_images?.second?.[product.sku] ? data.plp_images?.second?.[product.sku] : 'alt1'
  }
  return (
    <div className={style.thumbWrapper}>
      <img
        alt={product.sku}
        className={`${style.thumbImage} ${style.thumbImageDefault}`}
        src={getImageUrl(firstImg.sku, firstImg.variant, firstImg.type)}
      />
      <img
        alt={product.sku}
        className={`${style.thumbImage} ${style.thumbImageAlt}`}
        src={getImageUrl(secondImg.sku, secondImg.variant, secondImg.type)}
      />
      {isLast && <Plus className={style.plusIcon} />}
    </div>
  );
}
